import { AppGlobals } from './../AppGlobals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  baseAppUrl = AppGlobals.baseAppUrl;
  GENERATETOKENAPIURL=this.baseAppUrl + "Authenticate/generateTokenKey"
  
  constructor(private http: HttpClient) { }

  listadded = new Subject();

  GetData(ControllerName: string, ActionName: string, queryString: any) {
    let userToken = localStorage.getItem("accessToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
  
      return this.http.get(this.baseAppUrl + ControllerName + "/" + queryString, { headers: reqHeader });
  
  }

  PostData(ControllerName: string, data: any) {
    let userToken = localStorage.getItem("accessToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    return this.http.post(this.baseAppUrl + ControllerName, data, {headers: reqHeader , responseType: 'text' });
  }
  PostData2(ControllerName: string, methodName:string,data: any) {
    let userToken = localStorage.getItem("accessToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    return this.http.post(this.baseAppUrl + ControllerName +"/"+ methodName, data, {headers: reqHeader , responseType: 'text' });
  }
  GetPaginationData(ControllerName: string, ActionName: string, data: any): Observable<any[]> {
    let userToken = localStorage.getItem("accessToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    return this.http.post<any>(this.baseAppUrl + ControllerName + "/" + ActionName, data, { headers: reqHeader });
  }
  PutData(ControllerName: string, data: any): Observable<any[]> {
    let userToken = localStorage.getItem("accessToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    return this.http.put<any>(this.baseAppUrl + ControllerName, data,{ headers: reqHeader });
  }
  generateToken(){
    const obj={
      userName:"oicAngular",
      password:"MrR19159",
      apiKey:"bnKnBraopTZB7PBdIlx7CSV1bDmDtGkZ"
    }
  return this.http.post(this.GENERATETOKENAPIURL , obj, {
    
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
       }).pipe(
        map((user: any) => {
          
          localStorage.setItem('accessToken', user.token);
          return user;
  
        },error=>
        {
  
          return error;
        }),
       
    )};
}
